<template>
  <div class="pages">
    <div class="step">
      <div class="item">优惠券领取成功</div>
      <div class="item">登录享开盒子APP使用优惠券</div>
      <div class="item">参与话费补贴活动</div>
    </div>
    <div class="btn"><van-button size="large" round block :url="config.app_download_url">点击立即话费充值</van-button><em></em></div>
    <div class="iconkefu" @click="kefu">
      <van-icon class-prefix="icon" name="kefu" size="40" />
      <span>我要投诉</span>
    </div>
    <div class="adv"><a :href="adv.link_url"><img :src="adv.image1" width="100%"/></a></div>

    <van-popup v-model="advModal" :style="{width:'88%',backgroundColor:'transparent'}">
      <a :href="adv.link_url"><img :src="adv.image2" width="100%"/></a>
      <div class="t-c mt35"><van-icon name="close" color="#fff" size="32" @click="()=>{advModal = false}"/></div>
    </van-popup>
  </div>
</template>
<script>

  import {GetAdvConfig} from '@/services/services'
  export default {
    name:'success',
    components: {},
    data () {
      return {
        config:{},
        adv:{},
        advModal:true,
      }
    },
    created(){
      if(sessionStorage.getItem('payConfig')){
        let payConfig = JSON.parse(sessionStorage.getItem('payConfig'))
        const {kefu_url,app_download_url} = payConfig
        this.config = {kefu_url,app_download_url}
      }else{
        this.$router.push({path:'/paycoupon',query:{type:'taw'}})
      }

    },
    computed:{
    },
    watch:{
    },
    mounted(){
      this.findAdvConfig()
    },
    methods:{
      findAdvConfig(){
        GetAdvConfig().then(result => {
          if(result.data.code==1){
            this.adv = result.data.data
            // let leng=this.adv.image2.length
            // let key = Math.floor(Math.random() * leng)
            // this.adv.image2=this.adv.image2[key]
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      kefu(){
        this.$router.push({path:'/service'})
      }
    }
  }

</script>
<style scoped lang="less">
  .pages{padding:20px;
    .step{padding-left:40px;background:url('@{static-dir}step.png') no-repeat left center;background-size:32px auto;
      .item{font-size:16px;padding:18px 0px;font-weight:700;}
    }
    .btn{margin-top:20px;position:relative;
      .van-button{background-image:linear-gradient(to right,#A2EDFD,#7D65F6);color:#fff;
        .van-button__text{font-weight:700;}
      }
      em{width:60px;height:60px;background:url('@{static-dir}hand.png') no-repeat;background-size:100% auto;display:inline-block;position:absolute;right:60px;top:25px;z-index:1;}
    }
    .iconkefu{color:#8357F3;width:56px;display:inline-block;background:#fff;border-radius:6px;text-align:center;position:fixed;right:6px;top:40%;padding:8px;transform:scale(0.8);
      span{display:inline-block;width:100%;margin-top:5px;font-size:12px;}
    }
  }
  .adv{text-align:center;margin-top:80px;}
</style>
