const HTTP_URL=process.env.VUE_APP_API
module.exports = {
  getcode:`${HTTP_URL}api/sendCode`,

  payconfig:`${HTTP_URL}api/getConfig`,
  boxinfo:`${HTTP_URL}index/getBoxById`,
  createOrder:`${HTTP_URL}newboxapi/createOrder`,
  getOrderStatus:`${HTTP_URL}newboxapi/getOrderStatus`,

  getAdvConfig:`${HTTP_URL}api/getAdvConfig`,

  config:`${HTTP_URL}shop/index/getConfig`,


}
